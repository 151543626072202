async function fetchExpensesAttachedToProperty(propertyId, userKey) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties/${propertyId}/expenses`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userKey}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching expenses attached to property", error);
    throw new Error(error);
  }
}

async function addExpenseByUser(propertyId, expenseData, userKey) {
  console.log("🚀 ~ addExpenseByUser ~ expenseData:", expenseData, userKey);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties/${propertyId}/expenses`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userKey}`,
        },
        body: JSON.stringify(expenseData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error || "An error occurred while adding the expense."
      );
    }

    const resData = await response.json();
    console.log("🚀 ~ addExpenseByUser ~ resData:", resData);
    return resData; // Return the response data if needed
  } catch (error) {
    throw new Error("Failed to add expense. Please try again later.");
  }
}

export { fetchExpensesAttachedToProperty, addExpenseByUser };
