import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../helpers/utils/hooks/useAuth";

const ProtectedRoutes = () => {
  const { user } = useAuth();

  return user ? <Outlet /> : <Navigate to="/auth" replace />;
};

export default ProtectedRoutes;
