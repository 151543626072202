import styles from "./horizontalBorder.module.css";

const HorizontalBorder = () => {
  return (
    <>
      {/* <!-- Horizontal Border --> */}
      <div className={styles.horizontalBorder}></div>
    </>
  );
};

export default HorizontalBorder;
