import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useAuth } from "../../../helpers/utils/hooks/useAuth";
import { addExpenseByUser } from "../../../services/expensesService";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Container from "../../../components/WrapperComponents/AppContainer/appContainer";
import styles from "./styles/expenses.module.css";

const AddNewExpense = () => {
  const { propertyId } = useParams();
  const [formData, setFormData] = useState({
    amount: "",
    description: "",
    category: "", // Ensure category is "income" or "expense"
  });

  const [errors, setErrors] = useState({});

  const { user } = useAuth();
  const userKey = user?.jwt;
  const navigate = useNavigate();

  const location = useLocation();
  const propertyDetails =
    location.state?.propertyDetails ||
    JSON.parse(localStorage.getItem("propertyDetails"));

  useEffect(() => {
    if (!location.state?.propertyDetails && propertyDetails) {
      // Store propertyDetails in localStorage on initial load
      localStorage.setItem("propertyDetails", JSON.stringify(propertyDetails));
    }
  }, [location.state?.propertyDetails, propertyDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Simple client-side validation
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        name === "category" && !["income", "expense"].includes(value)
          ? "Category must be 'income' or 'expense'"
          : value.trim() === ""
          ? "This field is required"
          : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "" && key !== "category") {
        newErrors[key] = "This field is required";
      }
      if (
        key === "category" &&
        !["income", "expense"].includes(formData[key])
      ) {
        newErrors[key] = "Category must be 'income' or 'expense'";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await addExpenseByUser(propertyId, formData, userKey); // Call the service function with form data
      alert("Expense added successfully!");
      // Optionally, reset the form
      setFormData({
        amount: "",
        description: "",
        category: "",
      });
      setErrors({});
    } catch (error) {
      alert("Failed to add expense. Please try again");
      console.error(error);
    } finally {
      navigate(`/properties/${propertyId}/expenses`);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <p className={styles.textContentHeading}>Add New Expense</p>
        <p className={styles.textContent}>
          #
          {`${propertyId}  ${propertyDetails?.house_location}` ||
            "Fetching......"}
        </p>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} my={4}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount"
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.amount}
                helperText={errors.amount}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.description}
                helperText={errors.description}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.category}
              >
                <InputLabel>Category</InputLabel>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  label="Category"
                >
                  <MenuItem value="income">Income</MenuItem>
                  <MenuItem value="expense">Expense</MenuItem>
                </Select>
                {errors.category && (
                  <Typography color="error">{errors.category}</Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Add Expense
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default AddNewExpense;
