import { Container, Input, TextField, Stack, Button, Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import dayjs from "dayjs";
import styles from "../styles/rentalAppointment.module.css";

const RentalAppointment = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();

  const [dateValue, setNewDateValue] = useState(dayjs(new Date()));
  const [nameValue, setNameValue] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleDateChange = (newValue) => {
    setNewDateValue(newValue);
  };

  const handleNameChange = (event) => {
    setNameValue(event.target.value);
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    const formData = {
      data: {
        email,
        dateTime: dateValue.$d,
        clientName: nameValue,
        clientPhoneNumber: phoneNumber,
        propertyId,
        followedUp: false,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOOPHOLE_API}/bookings`,
        {
          //http://localhost:1337/api/bookings http://144.126.255.114/api/bookings
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.status == 200) {
        alert("Appointment Made");
        navigate("/");
      } else {
        alert("Appointment Not Made. Please Try Again");
        setNewDateValue(dayjs(new Date()));
        setNameValue("");
        setPhoneNumber("");
        setEmail("");
      }
      const responseJson = await response.json();
    } catch (error) {
      alert("Appointment Not Made. Please Try Again");
      setNewDateValue(dayjs(new Date()));
      setNameValue("");
      setPhoneNumber("");
      setEmail("");
      console.log(
        "🚀 ~ file: [propertyId].js:38 ~ handleSubmitForm ~ error happened:::",
        error
      );
    }
  };

  return (
    <Container>
      <div>
        <h1> APOOINTMENT FORM </h1>
        <form onSubmit={handleSubmitForm}>
          <Stack spacing={4}>
            <label>
              Name:
              <Input
                sx={{ px: 2, width: "90%" }}
                required
                value={nameValue}
                id="outlined-required"
                label="Name"
                placeholder="Insert Your Full Name"
                onChange={handleNameChange}
              />
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                error={false}
                label="Date&Time picker"
                value={dateValue}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            <TextField
              required
              type="tel"
              id="outlined-basic"
              label="Insert Your Phone Number"
              variant="outlined"
              // placeholder="Insert Your Phone Number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />

            <TextField
              required
              type="email"
              id="outlined-basic"
              label="Insert Your Email"
              variant="outlined"
              // placeholder="Insert Your Phone Number"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            <Box display="flex" justifyContent="space-between">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </Stack>
        </form>
      </div>
    </Container>
  );
};

export default RentalAppointment;
