import styles from "./index.module.css";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, CardContent, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PropertyCard = ({ propertyDetails }) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={4} sx={{ p: 2 }}>
      <Card
        sx={{
          minWidth: 250,
          border: "2px solid #a8a8a8",
          borderRadius: "40px",
        }}
      >
        <CardMedia
          sx={{ height: 200 }}
          image="https://le-cdn.hibuwebsites.com/4289fde35330438e800899f332da9581/dms3rep/multi/opt/h4-396w.jpg"
          title="green iguana"
        />
        <CardContent sx={{ height: 320 }}>
          <p className={styles.listingsCardPriceText}>
            ZMW {propertyDetails?.price || "-"}/pm
          </p>
          <div>
            <p className={styles.listingsCardPropertyDetails}>
              <span className={styles.listingsCardPropertyDetailsFontBold}>
                - {propertyDetails?.house_number_of_bedrooms || "-"}
              </span>{" "}
              bedrooms,{" "}
              <span className={styles.listingsCardPropertyDetailsFontBold}>
                {propertyDetails?.house_number_of_bathrooms || "-"}
              </span>{" "}
              bathrooms
            </p>
            {propertyDetails?.house_square_area && (
              <p className={styles.listingsCardPropertyDetails}>
                <span className={styles.listingsCardPropertyDetailsFontBold}>
                  {" "}
                  - 240
                </span>
                m2 land
              </p>
            )}
            <p
              className={`${styles.listingsCardPropertyDetails} ${styles.listingsCardPropertyDetailsFontBold}`}
            >
              {" "}
              - {propertyDetails?.house_location || "-"}{" "}
            </p>

            <button
              className={styles.moreTextButton}
              onClick={() => {
                // setOpen(true);
                navigate(`/rentalListings/${propertyDetails?.id}`, {
                  state: {
                    propertyDetails: {
                      propertyId: propertyDetails.id,
                      houseNumberOfBedrooms:
                        propertyDetails.house_number_of_bedrooms,
                      price: propertyDetails.price,
                      houseNumberOfBathrooms:
                        propertyDetails.house_number_of_bathrooms,
                      houseSquareArea: propertyDetails.house_square_area,
                      houseLocation: propertyDetails.house_location,
                      housePicturesUrls: propertyDetails.house_pictures_urls,
                      housePropertyDetails:
                        propertyDetails.house_property_details,
                    },
                  },
                });
              }}
            >
              <p>MORE</p>
            </button>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PropertyCard;
