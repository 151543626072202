async function fetchPropertiesForSale() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties?for_sale=true`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching properties", error);
  }
}

async function fetchPropertiesForRent() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties?for_rent=true`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching properties", error);
  }
}

async function fetchPropertiesOwnedBySignedInUser(user_id, userKey) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties?user_id=${user_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userKey}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching properties ofsigned in user", error);
    throw new Error(error);
  }
}

async function addPropertyByUser(propertyData, userKey) {
  console.log("🚀 ~ addPropertyByUser ~ propertyData:", propertyData, userKey);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userKey}`,
        },
        body: JSON.stringify(propertyData),
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error || "An error occurred while adding the property."
      );
    }
    const resData = await response.json();
    console.log("🚀 ~ fetchPropertiesOwnedBySignedInUser ~ resData:", resData);
  } catch (error) {
    throw new Error("Failed to add Property. Please try again later.");
  }
}

async function deletePropertyOwnedByUser(propertyDataId, userKey) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/properties/${propertyDataId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userKey}`,
        },
      }
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.error || "An error occurred while deleting the property."
      );
    }
    const resData = await response.json();
    console.log("🚀 ~ deletePropertyOwnedByUser ~ resData:", resData);
  } catch (error) {
    throw new Error("Failed to delete Property. Please try again later.");
  }
}

export {
  fetchPropertiesForSale,
  fetchPropertiesForRent,
  fetchPropertiesOwnedBySignedInUser,
  addPropertyByUser,
  deletePropertyOwnedByUser,
};
