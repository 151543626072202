import * as React from "react";
import styles from "./styles/index.module.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Card, CardContent, CardMedia } from "@mui/material";
import homeProspectiveImage from "../../assets/images/home/firstHomeImageProspective.jpeg";
import realEstateAboutHomeImage from "../../assets/images/home/realEstateAboutHomeImage.jpg";
import Grid from "@mui/material/Unstable_Grid2";
import {
  fetchPropertiesForRent,
  fetchPropertiesForSale,
} from "../../services/propertiesService";
import FeaturedRentalListings from "./featuredRentalListings";
import FeaturedSalesListings from "./featuredSalesListings";

export default function Home() {
  const [propertiesForRent, setPropertiesForRent] = useState([]);
  const [propertiesForSale, setPropertiesForSale] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assuming fetchData is an async function fetching data from an API
        const rentJsonData = await fetchPropertiesForRent();
        const salesJsonData = await fetchPropertiesForSale();
        if (rentJsonData) {
          setPropertiesForRent(rentJsonData);
        }
        if (salesJsonData) {
          setPropertiesForSale(salesJsonData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the async function to fetch data
    fetchData();
  }, []);

  return (
    <>
      <Grid className={styles.homePageGridContainer} container>
        <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
          <div className={styles.gridTextContainer}>
            <p className={styles.homeLoopholeHeaderText}>
              {" "}
              Your <span className={styles.homeLoopholeDreamText}>
                {" "}
                Dream{" "}
              </span>{" "}
            </p>
            <p className={styles.homeLoopholeHeaderText}> Home Awaits </p>
            <p className={styles.homeLoopholeMainText}>
              {" "}
              Discover your dream home, sell, and manage your property online!
            </p>
            <Link to="/contactUs">
              <button
                name="button"
                className={styles.viewListingsButton}
                style={{ marginLeft: "1.8rem" }}
              >
                <span className={styles.buttonFont}> Get In Touch </span>
              </button>
            </Link>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 1, sm: 2 }}
          display="flex"
          justifyContent="center"
          alignContent="center"
        >
          <Box>
            <img
              alt="prospective house"
              src={homeProspectiveImage}
              className={styles.prospectiveHouseImage}
            />
          </Box>
          {/* </div> */}
        </Grid>
      </Grid>

      <FeaturedRentalListings propertiesForRent={propertiesForRent} />

      <FeaturedSalesListings propertiesForSale={propertiesForSale} />

      <div className={styles.borderPageDivider} />

      <Grid className={styles.homePageAboutUsGridContainer} container px={3}>
        <Grid item xs={12} sm={6}>
          <img
            alt="prospective house"
            src={realEstateAboutHomeImage}
            layout="responsive"
            className={styles.prospectiveHouseImage}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={styles.gridTextAboutContainer}>
            <p className={styles.homeLoopholeAboutHeaderTitle}> ABOUT US </p>
            <p className={styles.homeLoopholeAboutHeaderText}>
              A Real Estate Company with a Difference!
            </p>
            <p className={styles.homeLoopholeMainText}>
              {" "}
              Welcome to Loophole, where we're reshaping real estate through
              cutting-edge technology. Our platform caters to professionals,
              homeowners, investors, and tenants, offering a seamless experience
              in renting, buying, and selling. Powered by our in-house tech
              team, we prioritize simplicity without compromising the crucial
              guidance of real estate experts. For renters, finding the perfect
              home is easy, with hassle-free searches, bookings, and offers
              supported by on-ground professionals. Buyers enjoy a digitized
              process from search to closing, while sellers can effortlessly
              list their properties and access valuation experts for maximizing
              value. In collaboration with top valuation firms, real estate
              brokerages, banks, agents, and property managers, we aim to be the
              go-to platform for all real estate needs, making transactions
              faster and more efficient for everyone involved. Welcome to a new
              era in real estate with Loophole
            </p>
            <Link to="./about">
              <button name="button" className={styles.aboutUsButton}>
                <span className={styles.aboutUsbuttonFont}> About Us </span>
              </button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
