import React, { useRef, useState } from "react";

// css
import styles from "./styles/index.module.css";

// material UI components
import { TextField, Button, Stack, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const navigate = useNavigate();
  const form = useRef();
  const [email, setEmail] = useState("");
  const [name, setNameValue] = useState("");
  const [message, setMessageValue] = useState("");

  const [isFalseEmail, setIsFalseEmail] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const sendEmail = async (event) => {
    event.preventDefault();
    const formData = {
      customerName: name,
      customerEmail: email,
      customerMessage: message,
    };

    try {
      const response = await fetch(
        "https://api.loophole.properties/contact_messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiZXhwIjoxNjg3MTI0Mjk0fQ.27lC5qUPepkpgNsspDIhBzzMccvmuzajOsTf8C5MfrE",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.status == 201) {
        alert("Message Sent! Please expect feedback via email / phone");
        navigate("/");
      } else {
        alert("Message Not Sent. Please Try Again");
        setNameValue("");
        setMessageValue("");
        setEmail("");
      }
      const responseJson = await response.json();
    } catch (error) {
      console.log(
        "🚀 ~ file: [propertyId].js:38 ~ handleSubmitForm ~ error happened:::",
        error
      );
      alert("Appointment Not Made. Please Try Again");
      setNameValue("");
      setMessageValue("");
      setEmail("");
    }
  };

  return (
    <section className={styles.contactContainer}>
      {/* <p className={styles.contactHeading}>Get in touch</p> */}
      <form ref={form} onSubmit={sendEmail}>
        <Stack margin={1} spacing={4}>
          <TextField
            name="user_name"
            label="Name"
            variant="outlined"
            size="small"
            required
            value={name}
            onChange={(e) => setNameValue(e.target.value)}
          />
          <TextField
            sx={{ my: 3 }}
            name="email"
            // error={isFalseEmail}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="outlined"
            size="small"
            required
          />
          <TextField
            // sx={{ my: 1 }}
            name="message"
            label="Message"
            variant="outlined"
            size="small"
            value={message}
            onChange={(e) => setMessageValue(e.target.value)}
            multiline
            required
            rows={4}
          />

          <Button variant="contained" color="info" type="submit">
            Send
          </Button>
        </Stack>
      </form>

      <Snackbar
        open={isEmailSent}
        autoHideDuration={3000}
        onClose={() => setIsEmailSent(false)}
      >
        <Alert
          onClose={() => setIsEmailSent(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Email Sent!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isFalseEmail}
        autoHideDuration={3000}
        onClose={() => setIsFalseEmail(false)}
      >
        <Alert
          onClose={() => setIsFalseEmail(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          Invalid Email!
        </Alert>
      </Snackbar>
    </section>
  );
}
