import { Grid, Card, CardContent, CardMedia, CardActions } from "@mui/material";
import { useState } from "react";
import RentalPropertyModal from "./rentalPropertyModal";
import { useNavigate } from "react-router-dom";

import styles from "./styles/rentalPropertyCard.module.css";

const RentalPropertyCard = ({
  cardImageSource,
  propertyDetails,
  onClickMoreFunction,
  showMoreBoolean,
  setShowMoreBoolean,
}) => {
  const handleCloseModal = () => {
    setOpen(!open);
  };

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <RentalPropertyModal
          propertyDetails={propertyDetails}
          modalVisibleBoolean={open}
          handleCloseModal={handleCloseModal}
        />

        <Card
          sx={{
            maxWidth: 345,
            height: 550,
            border: "2px solid #a8a8a8",
            borderRadius: "40px",
            margin: "10px 5px",
          }}
        >
          <CardMedia
            sx={{ height: 180 }}
            image={cardImageSource}
            title="green iguana"
          />
          <CardContent>
            <p className={styles.listingsCardPriceText}>
              ZMW {propertyDetails.price}/pm
            </p>
            <div>
              <p className={styles.listingsCardPropertyDetails}>
                <span className={styles.listingsCardPropertyDetailsFontBold}>
                  - {propertyDetails.houseNumberOfBedrooms}
                </span>{" "}
                bedrooms,{" "}
                <span className={styles.listingsCardPropertyDetailsFontBold}>
                  {propertyDetails.houseNumberOfBathrooms}
                </span>{" "}
                bathrooms
              </p>
              <p className={styles.listingsCardPropertyDetails}>
                <span className={styles.listingsCardPropertyDetailsFontBold}>
                  {" "}
                  - {propertyDetails.houseSquareArea}
                </span>
                m2 land
              </p>
              <p
                className={`${styles.listingsCardPropertyDetails} ${styles.listingsCardPropertyDetailsFontBold}`}
              >
                {" "}
                - {propertyDetails.houseLocation}{" "}
              </p>
            </div>
          </CardContent>
          <CardActions>
            <button
              className={styles.moreTextButton}
              onClick={() => {
                // setOpen(true);
                navigate(`/rentalListings/${propertyDetails.propertyId}`, {
                  state: { propertyDetails },
                });
              }}
            >
              <p>MORE</p>
            </button>
          </CardActions>
        </Card>
      </Grid>
      <Grid></Grid>
    </>
  );
};

export default RentalPropertyCard;
