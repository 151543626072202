import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import { useAuth } from "../../../helpers/utils/hooks/useAuth";
import { fetchExpensesAttachedToProperty } from "../../../services/expensesService";
import Container from "../../../components/WrapperComponents/AppContainer/appContainer";
import styles from "./styles/expenses.module.css";
import HorizontalBorder from "../../../components/utils/horozontalBorder/horizontalBorder";

const ViewAllExpenses = () => {
  const { propertyId } = useParams();
  const [expenses, setExpenses] = useState([]);
  const [income, setIncome] = useState([]);
  const [fee, setFee] = useState(0);
  const [difference, setDifference] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const { user } = useAuth();
  const userKey = user?.jwt;

  const location = useLocation();
  const propertyDetails =
    location.state?.property ||
    JSON.parse(localStorage.getItem("propertyDetails"));

  useEffect(() => {
    if (!location.state?.property && propertyDetails) {
      // Store propertyDetails in localStorage on initial load
      localStorage.setItem("propertyDetails", JSON.stringify(propertyDetails));
    }
  }, [location.state?.property, propertyDetails]);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const data = await fetchExpensesAttachedToProperty(propertyId, userKey);

        if (!Array.isArray(data)) {
          setErrorMessage("Unexpected data format. Please refresh.");
          setIncome([]);
          setExpenses([]);
          setDifference(0);
          return;
        }

        if (data.length === 0) {
          setErrorMessage("");
          setIncome([]);
          setExpenses([]);
          setDifference(0);
          return;
        }

        let totalIncome = 0;
        let totalExpenses = 0;
        const incomeData = [];
        const expensesData = [];

        data.forEach((item) => {
          if (
            item &&
            typeof item.amount === "string" &&
            item.description &&
            item.category &&
            !isNaN(parseFloat(item.amount))
          ) {
            const amount = parseFloat(item.amount);
            if (item.category === "income") {
              incomeData.push(item);
              totalIncome += amount;
            } else if (item.category === "expense") {
              expensesData.push(item);
              totalExpenses += amount;
            }
          } else {
            console.warn("Invalid expense data found and skipped:", item);
          }
        });

        setIncome(incomeData);
        setExpenses(expensesData);
        const fee = (totalExpenses + totalIncome) * 0.05;
        setFee(fee);
        setDifference(totalIncome - totalExpenses - fee);
        setErrorMessage("");
      } catch (error) {
        console.error("Error fetching expenses:", error);
        setErrorMessage("Error fetching expenses. Please refresh.");
        setIncome([]);
        setExpenses([]);
        setDifference(0);
      }
    };

    fetchExpenses();
  }, [propertyId, userKey]);

  console.log("feee", fee);
  if (!propertyDetails) {
    return (
      <Container>
        <Box mt={4}>
          <Typography variant="h6" color="error">
            Property details not available. Please go back and try again.
          </Typography>
          <Link to="/properties">
            <button className={styles.button}>Go Back to Properties</button>
          </Link>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <p className={styles.textContentHeading}>Income & Expenses</p>
      <p className={styles.textContent}>
        #
        {`${propertyId}  ${propertyDetails?.house_location}` ||
          "Fetching......"}
      </p>
      {errorMessage ? (
        <Box mt={4}>
          <Typography variant="h6" color="error">
            {errorMessage ||
              " Property details not available. Please go back and try again."}
          </Typography>
          <Link to="/properties">
            <button className={styles.button}>Go Back to Properties</button>
          </Link>
        </Box>
      ) : (
        <>
          {expenses.length === 0 && income.length === 0 ? (
            <>
              <div className={styles.noExpensesMessage}>
                <p>No expenses for this property.</p>
              </div>

              <div className={styles.addButtonContainer}>
                <Link
                  to={`/properties/${propertyId}/expenses/addNewExpense`}
                  state={{ propertyDetails: propertyDetails }}
                  className={styles.addButton}
                >
                  Add Expense
                </Link>
                <Link
                  className={styles.goBackButton}
                  to={`/properties/${propertyId}`}
                  state={{ propertyDetails: propertyDetails }}
                >
                  Go Back
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th className={styles.tableHeading}>Income</th>
                      <th className={styles.tableHeading}>Expenditure</th>
                    </tr>
                  </thead>

                  <tbody>
                    {income.map((item) => (
                      <tr key={item.id}>
                        <td className={styles.tableCell}>
                          ZMW {item.amount} - {item.description}
                        </td>
                        <td className={styles.tableCell}></td>
                      </tr>
                    ))}
                    {expenses.map((item) => (
                      <tr key={item.id}>
                        <td className={styles.tableCell}></td>
                        <td className={styles.tableCell}>
                          ZMW {item.amount} - {item.description}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className={styles.feeContainer}>
                  <p>Less Loophole management fees @5%: </p>
                  <p>ZMW {fee}</p>
                </div>

                <div className={styles.totalDifference}>
                  <p>Net Income: </p>
                  <p className={styles.netDifferenceText}> ZWM {difference} </p>
                </div>
              </div>

              <div className={styles.addButtonContainer}>
                <Link
                  to={`/properties/${propertyId}/expenses/addNewExpense`}
                  state={{ propertyDetails: propertyDetails }}
                  className={styles.addButton}
                >
                  Add Expense
                </Link>
                <Link
                  className={styles.goBackButton}
                  to={`/properties/${propertyId}`}
                  state={{ propertyDetails: propertyDetails }}
                >
                  Go Back
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ViewAllExpenses;
