import { useState } from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './AuthForm.module.css';

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const HandleEmailInputChange = (event) => {
    setEmail(event.target.value);
  }

  const HandlePasswordInputChange = (event) => {
    setPassword(event.target.value);
  }

  const HandleSubmitSignUpForm = async (event) => {
    event.preventDefault();
    console.log("🚀 ~ HandleSubmitSignUpForm ~ email:", email,"password::", password)
    try {
      // const signUpResponse = await fetch('https://api.loophole.properties/register', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     email: email,
      //     password: password,
      //   }),
      // });
      // const signUpResponseData = await signUpResponse.json();
      // console.log("🚀 ~ HandleSubmitSignUpForm ~ data:", signUpResponseData);
      // if (signUpResponse.status === 200) {
      //   localStorage.setItem('token', signUpResponseData);
      //   // window.location.reload();
      // } else {
      //   setErrorMessage(signUpResponseData.message);
      }
    catch (error) {
      console.log("error", error.message);
      alert(error.message);
      return;
    }

    return (
      <form action="#" className={styles["sign-up-form"]} onSubmit={(event) => {HandleSubmitSignUpForm(event)}}>
        {/* sign-up form  */}
        <h2 className={styles.title}>Sign up</h2>
          <div className={styles["input-field"]}>
            <i className="fas fa-user"></i>
            <input type="text" placeholder="Username" />
          </div>
          <div className={styles["input-field"]}>
            <i className="fas fa-envelope"></i>
            <input type="email" placeholder="Email" onChange={(event) => HandleEmailInputChange(event)} />
          </div>
          <div className={styles["input-field"]}>
            <i className="fas fa-lock"></i>
            <input type="password" placeholder="Password" onChange={(event) => HandlePasswordInputChange(event)}/>
          </div>
          <input type="submit" className={styles.btn} value="Sign up" />
          {/* <p class="social-text">Or Sign up with social platforms</p>
            // Social media icons 
            <div class="social-media">
            <a href="#" class="social-icon">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="#" class="social-icon">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="social-icon">
              <i class="fab fa-google"></i>
            </a>
            <a href="#" class="social-icon">
              <i class="fab fa-linkedin-in"></i>
            </a>
            </div> */}
        </form>
    )
  }
}

export default SignUpForm;
