import { useState } from "react";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";

import { addPropertyByUser } from "../../../services/propertiesService";
import { useAuth } from "../../../helpers/utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Container from "../../../components/WrapperComponents/AppContainer/appContainer";
import styles from "./styles/addNewProperty.module.css";

const AddNewProperty = () => {
  const [formData, setFormData] = useState({
    price: "",
    house_number_of_bedrooms: "",
    house_number_of_bathrooms: "",
    house_square_area: "",
    house_location: "",
    house_property_details: "",
  });

  const [errors, setErrors] = useState({});

  const { user } = useAuth();
  const userKey = user?.jwt;
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === "" ? "This field is required" : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === "") {
        newErrors[key] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await addPropertyByUser(formData, userKey); // Call the service function with form data
      alert("Property added successfully!");
      // Optionally, reset the form
      setFormData({
        price: "",
        house_number_of_bedrooms: "",
        house_number_of_bathrooms: "",
        house_square_area: "",
        house_location: "",
        house_property_details: "",
      });
      setErrors({});
    } catch (error) {
      alert("Failed to add property. Please try again");
      console.error(error);
    } finally {
      navigate(`/properties`);
    }
  };

  return (
    <Container backgroundColour="#0B4FB5">
      <Box sx={{ mt: 4, pl: 6 }}>
        <p className={styles.textContentHeading}>Add New Property</p>
        <p className={styles.textContent}>
          Let's get started with your new listing
        </p>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Price"
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.price}
                helperText={errors.price}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Number of Bedrooms"
                type="number"
                name="house_number_of_bedrooms"
                value={formData.house_number_of_bedrooms}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.house_number_of_bedrooms}
                helperText={errors.house_number_of_bedrooms}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Number of Bathrooms"
                type="number"
                name="house_number_of_bathrooms"
                value={formData.house_number_of_bathrooms}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.house_number_of_bathrooms}
                helperText={errors.house_number_of_bathrooms}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="House Square Area"
                type="number"
                name="house_square_area"
                value={formData.house_square_area}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.house_square_area}
                helperText={errors.house_square_area}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="House Location"
                type="text"
                name="house_location"
                value={formData.house_location}
                onChange={handleChange}
                variant="outlined"
                error={!!errors.house_location}
                helperText={errors.house_location}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="House Property Details"
                type="text"
                name="house_property_details"
                value={formData.house_property_details}
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={4}
                error={!!errors.house_property_details}
                helperText={errors.house_property_details}
              />
            </Grid>
            <Grid item xs={12}>
              <button className={styles.submitButton}>Submit</button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default AddNewProperty;
