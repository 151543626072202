import React from "react";
import "./index.css";
import "./fonts.css";

import ErrorPage from "./error-page";
import Home from "./routes/home";
import SaleslListings from "./routes/salesListings";
import RentalListings from "./routes/rentalListings";
import RentalListingDetails from "./routes/rentalListings/rentalListingsDetails";
import RentalAppointment from "./routes/rentalListings/rentalAppointment";
import ContactUs from "./routes/contactUs";
import About from "./routes/about";
import Navbar from "./components/nav/nav";
import AuthForm from "./routes/authForms/AuthForm";
import ProductTourF from "./routes/productTour1/ProductTourF";
import ProductTourS from "./routes/productTour2/Pt";
import ProductTourT from "./routes/productTour3/PtT";
import ImageUpload from "./routes/addListing/addListing1";
import AddListing2 from "./routes/addListing/addListing2";
import AddListing3 from "./routes/addListing/addListing3";
import AddListing4 from "./routes/addListing/addListing4";
import Dashboard from "./routes/LandingPage/Lp";
import AddNewProprty from "./routes/manager/properties/addNewProperty";
import PropertiesListManager from "./routes/manager/properties/propertyIndex";
import PropertyDetails from "./routes/manager/properties/propertyDetails";
import ViewAllExpenses from "./routes/manager/expenses/viewAllExpenses";
import AddNewExpense from "./routes/manager/expenses/addNewExpense";

import ProtectedRoutes from "./components/ProtectedRoute/ProtectedRoute";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./helpers/utils/hooks/useAuth";

const router = createBrowserRouter([
  {
    element: <Navbar />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/salesListings",
        element: <SaleslListings />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/rentalListings",
        element: <RentalListings />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/rentalListings/:propertyId",
        element: <RentalListingDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/rentalListings/rentalAppointment/:propertyId",
        element: <RentalAppointment />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/contactUs",
        element: <ContactUs />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/about",
        element: <About />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/auth",
        element: <AuthForm />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/productTourF",
    element: <ProductTourF />,
    errorElement: <ErrorPage />,
  },
  {
    element: <ProtectedRoutes />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/productTourS",
        element: <ProductTourS />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/productTourT",
        element: <ProductTourT />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/addListing1",
        element: <ImageUpload />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/addListing2",
        element: <AddListing2 />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/addListing3",
        element: <AddListing3 />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/addListing4",
        element: <AddListing4 />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/properties",
        element: <PropertiesListManager />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/properties/addNewProperty",
        element: <AddNewProprty />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/properties/:propertyId",
        element: <PropertyDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: "properties/:propertyId/expenses",
        element: <ViewAllExpenses />,
        errorElement: <ErrorPage />,
      },
      {
        path: "properties/:propertyId/expenses/addNewExpense",
        element: <AddNewExpense />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
