import styles from "./AuthForm.module.css";
import { useState } from "react";
import { useAuth } from "../../helpers/utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {

  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const HandleEmailInputChange = (event) => {
    setEmail(event.target.value);
  }

  const HandlePasswordInputChange = (event) => {
    setPassword(event.target.value);
  } 

  const HandleSubmitLoginForm = async (event) => {
    setErrorMessage('');
    event.preventDefault();
    console.log("🚀 ~ HandleSubmitLoginForm ~ email:", email,"password::", password);
    try {
      await login(email, password, setErrorMessage, navigate);
    } catch (error) {
      console.log("error", error);
      setErrorMessage(error);
      return;
    }
  }

  return (
    <form action="#" className={styles["sign-in-form"]} onSubmit={(event) => HandleSubmitLoginForm(event)}>
      {/*  sign-in form  */}
      <h2 className={styles.title}>Sign in</h2>
      <div className={styles["input-field"]}>
        <i className="fas fa-user"></i>
        <input type="text" placeholder="Username" onChange={(event) => HandleEmailInputChange(event)} />
      </div>
      <div className={styles["input-field"]}>
        <i className="fas fa-lock"></i>
        <input type="password" placeholder="Password" onChange={(event) => {HandlePasswordInputChange(event)}}/>
      </div>
      <input type="submit" value="Login" className={`${styles.btn} ${styles.solid}`} />

      {errorMessage &&
        <div style={{  color: 'white', padding: '10px', borderRadius: '5px' }}>
          <p> Error: {errorMessage}. </p>
        </div>
      }

      {/* // Social media icons
        <p class="social-text">Or Sign in with social platforms</p> 
      <div class="social-media">
      <a href="#" class="social-icon">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="#" class="social-icon">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="#" class="social-icon">
        <i class="fab fa-google"></i>
      </a>
      <a href="#" class="social-icon">
        <i class="fab fa-linkedin-in"></i>
      </a> */}
    </form>
  )
}

export default SignInForm;