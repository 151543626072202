import styles from "./styles/index.module.css";
import { Link } from "react-router-dom";
import PropertyScrollList from "../../components/propertyScrollList";

const FeaturedSalesListings = ({ propertiesForSale }) => {
  return (
    <>
      <p className={styles.homeLoopholeHeaderText}>
        Featured Properties for Sale
      </p>

      <PropertyScrollList properties={propertiesForSale} />

      <button
        name="button"
        className={styles.viewListingsButton}
        style={{ marginLeft: "1.8rem" }}
      >
        <Link to="/salesListings" style={{ textDecoration: "none" }}>
          <span className={styles.buttonFont}> View Sales Listings </span>
        </Link>
      </button>
    </>
  );
};

export default FeaturedSalesListings;
