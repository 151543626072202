
import React, { useState } from 'react';
import styles from './AuthForm.module.css';
import '@fortawesome/fontawesome-free/css/all.css';

import SignInForm from './signInForm';
import SignUpForm from './signUpForm';

const AuthForm = () => {
  const [isSignUp, setIsSignUp] = useState(false);

  const toggleMode = () => {
    setIsSignUp((prevMode) => !prevMode);
  };

  return (
    <div className={`${styles.container} ${isSignUp ? styles['sign-up-mode'] : ''}`}>
      <div className={styles["forms-container"]}>
        <div className={styles["signin-signup"]}>
          {isSignUp ? (
            <SignUpForm />
          ) : (
            <SignInForm />
          )}
        </div>
      </div>

      <div className={styles["panels-container"]}>
        <div className={`${styles.panel} ${styles["left-panel"]}`}>
          {/* Left panel content */}
          <div className={styles.content}>
            <h3>New here ?</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Debitis,
              ex ratione. Aliquid!
            </p>
          </div>
          <button className={`${styles.btn} ${styles.transparent}`} onClick={toggleMode}>
            {isSignUp ? 'Sign in' : 'Sign up'}
          </button>
          <img src="image/log.svg" className={styles.image} alt="" />
        </div>
        <div className={`${styles.panel} ${styles["right-panel"]}`}>
          {/* Right panel content */}
          <div className={styles.content}>
            <h3>One of us ?</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
              laboriosam ad deleniti.
            </p>
          </div>
          <button className={`${styles.btn} ${styles.transparent}`} onClick={toggleMode}>
            {isSignUp ? 'Sign in' : 'Sign up'}
          </button>
          <img src="image/register.svg" className={styles.image} alt="" />
        </div>
      </div>
    </div>

  );
};

export default AuthForm;



  