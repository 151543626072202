import { Box, Grid, Rating, Button, Typography } from "@mui/material";
import styles from "./index.module.css";
import Slideshow from "../../../components/imageSlider/imageSlider";
import RoomIcon from "@mui/icons-material/Room";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const RentalListingDetails = () => {
  const location = useLocation();

  const propertyDetails = location.state?.propertyDetails;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} md={4}>
        <div className={styles.moreDetailsImageSliderContainer}>
          <Slideshow propertyImageUrls={propertyDetails?.housePicturesUrls} />
        </div>
      </Grid>
      <Grid item xs={11} md={7}>
        <div className={styles.moreDetailsTextContainer}>
          <Box display="flex" sx={{ marginTop: "10px" }}>
            <RoomIcon sx={{ fontSize: 12, marginY: 0.45, marginX: 1 }} />
            <Typography variant="body2" sx={{ fontSize: 15 }}>
              {propertyDetails?.houseLocation}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ marginTop: "10px" }}
          >
            <Box display="flex">
              <Rating name="read-only" value={3} readOnly />
              <Typography component="legend" sx={{ color: "grey" }}>
                3.0
              </Typography>
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              ZMW {propertyDetails?.price}{" "}
            </Typography>
          </Box>

          <Box>
            <Typography variant="h6" my={2}>
              Property Detail
            </Typography>

            <Typography variant="body2" my={2}>
              {propertyDetails.housePropertyDetails}
            </Typography>

            <Box>
              <Link
                to={`/rentalListings/rentalAppointment/${encodeURIComponent(
                  propertyDetails.propertyId
                )}`}
              >
                <Button variant="contained">Book Appointment</Button>
              </Link>
            </Box>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default RentalListingDetails;
