import React from "react";
import { Box } from "@mui/material";
import styles from "./appContainer.module.css";
import Logo from "../../../assets/logos/horizontalLogoWhite.svg";

const Container = ({ children, backgroundColour }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#0B4FB5",
        minHeight: "100vh",
        padding: "80px 20px 40px",
        position: "relative",
      }}
    >
      <img src={Logo} alt="Logo" className={styles.logo} />
      <div className={styles.childrenContent}>{children}</div>
    </Box>
  );
};

export default Container;
