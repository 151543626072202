import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../../../helpers/utils/hooks/useAuth";
import { fetchPropertiesOwnedBySignedInUser } from "../../../services/propertiesService";
import Container from "../../../components/WrapperComponents/AppContainer/appContainer";
import styles from "./styles/property.module.css";

const PropertiesListManager = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const userKey = user?.jwt;
  const userId = user?.id; // Assuming you have user id available in user object

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchPropertiesOwnedBySignedInUser(userId, userKey);
        setProperties(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, userKey]);

  const handleAddNewProperty = () => {
    navigate("addNewProperty");
  };

  if (loading) {
    return (
      <Container>
        <Typography variant="h6">Loading properties...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <p className={styles.textContentHeading}>
        {properties.length < 1 ? "No Properties Added" : "All Properties"}
      </p>
      <p className={styles.textContent}>
        {properties.length < 1
          ? "You have not yet added any properties. Select add properties below to add a property."
          : "Here are all of your properties managed by Loophole!"}
      </p>
      {properties.length < 1 ? (
        <button
          className={styles.addPropertyButton}
          variant="contained"
          color="primary"
          onClick={handleAddNewProperty}
        >
          Add New Property
        </button>
      ) : (
        ""
      )}
      {properties.length > 0 && (
        <div className={styles.container}>
          {properties.map((property) => (
            <div key={property.id} className={styles.propertyItem}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.textContainer}>
                    <h6 className={styles.propertyId}>{property.id}</h6>
                    <h6 className={styles.propertyLocation}>
                      {property.house_location}
                    </h6>
                  </div>
                  <Link
                    to={`/properties/${property.id}`}
                    state={{ propertyDetails: property }}
                    className={styles.viewButton}
                  >
                    View
                  </Link>
                </div>
              </div>
            </div>
          ))}
          <button
            className={styles.addPropertyButton}
            variant="contained"
            color="primary"
            onClick={handleAddNewProperty}
          >
            Add New Property
          </button>
        </div>
      )}
    </Container>
  );
};

export default PropertiesListManager;
