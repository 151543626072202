import { useRouteError } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred. Please go <Link to="./dashboard"> Home </Link></p>
    </div>
  );
}