import styles from "./index.module.css";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import PropertyCard from "../propertyCard";
import { useState } from "react";

const PropertyScrollList = ({ properties }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const propertiesPerPage = 3;

  const handleNextClick = () => {
    if ((currentPage + 1) * propertiesPerPage < properties.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentProperties = properties.slice(
    currentPage * propertiesPerPage,
    (currentPage + 1) * propertiesPerPage
  );

  return (
    <div className={styles.iconGridContainer}>
      <button className={styles.navButton} onClick={handlePrevClick}>
        &lt;
      </button>
      <Grid container spacing={2}>
        {currentProperties.map((property, index) => (
          <PropertyCard key={index} propertyDetails={property} />
        ))}
      </Grid>

      <button className={styles.navButton} onClick={handleNextClick}>
        &gt;
      </button>
    </div>
  );
};

export default PropertyScrollList;
