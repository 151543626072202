import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);

  // call this function when you want to authenticate the user
  const login = async (email, password, setErrorMessage, navigate) => {
    const loginResponse = await fetch(
      `${process.env.REACT_APP_LOOPHOLE_API}/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      }
    );

    if (loginResponse.status === 200) {
      // Add user to context and local storage
      const loginResponseData = await loginResponse.json();
      console.log(
        "🚀 ~ HandleSubmitLoginForm ~ data:",
        loginResponseData,
        loginResponse
      );
      setUser(loginResponseData);
      console.log("🚀 ~ login ~ user data after save:", user);
      navigate("/dashboard");
    } else if (loginResponse.status === 401) {
      setErrorMessage("Login Failed. Please Refresh and try again");
    } else {
      setErrorMessage("Login Failed. Please Refresh and try again");
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    console.log("🚀 ~ logout ~ user: we are here on logging out");
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
