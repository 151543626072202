import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardMedia,
  Grid,
  Button,
} from "@mui/material";

import Container from "../../../components/WrapperComponents/AppContainer/appContainer";
import styles from "./styles/property.module.css";
import HorizontalBorder from "../../../components/utils/horozontalBorder/horizontalBorder";
import { deletePropertyOwnedByUser } from "../../../services/propertiesService";
import { useAuth } from "../../../helpers/utils/hooks/useAuth";

const PropertyDetails = () => {
  const location = useLocation();
  const [deleteError, setDeleteError] = useState(null);
  const [open, setOpen] = useState(false);

  const propertyDetails =
    location.state?.propertyDetails ||
    JSON.parse(localStorage.getItem("propertyDetails"));

  useEffect(() => {
    if (!location.state?.propertyDetails && propertyDetails) {
      // Store propertyDetails in localStorage on initial load
      localStorage.setItem("propertyDetails", JSON.stringify(propertyDetails));
    }
  }, [location.state?.propertyDetails, propertyDetails]);

  const { user } = useAuth();
  const userKey = user?.jwt;

  const navigate = useNavigate();

  function truncateLoacationStringForHeading(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength) + "...";
  }

  const handleViewExpenses = () => {
    if (propertyDetails) {
      navigate(`/properties/${propertyDetails.id}/expenses`, {
        state: { property: propertyDetails },
      });
    } else {
      alert(
        "Problem sending property details. Please go To Properties Page and refresh"
      );
    }
  };

  const handleDeleteClick = (property) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Reload the fetch
  };

  const handleConfirmDelete = async () => {
    try {
      console.log("Deleted property:", propertyDetails?.id);
      await deletePropertyOwnedByUser(propertyDetails?.id, userKey);
      handleClose();
      navigate(`/properties/`); // Navigate only on successful deletion
    } catch (error) {
      console.error("Failed to delete property:", error);
      setDeleteError("Failed to delete the property. Please try again."); // Set error message
      handleClose();
    }
  };

  return (
    <Container>
      <p className={styles.textContentHeading}>
        Plot #
        {`${propertyDetails?.id}, ${truncateLoacationStringForHeading(
          propertyDetails?.house_location,
          20
        )}` || "Fetching......"}
      </p>
      <p className={styles.textContent}>
        Plot #
        {`${propertyDetails?.id}, ${propertyDetails?.house_location}` ||
          "Fetching......"}
      </p>
      <Box mt={4} mb={4}>
        <div className={styles.propertyDetailsContainer}>
          <p className={styles.textContentHeadingPrice}>
            ZMW {` ${propertyDetails?.price}` || "Fetching......"}
          </p>
        </div>

        <div className={styles.propertyDetailsContentContainer}>
          <p className={styles.propertyDetailsContentText}>
            {` ${propertyDetails?.house_number_of_bedrooms} bedrooms` ||
              "Fetching......"}
          </p>
          <p className={styles.propertyDetailsContentText}>
            {` ${propertyDetails?.house_number_of_bathrooms} bathrooms` ||
              "Fetching......"}
          </p>
          <p className={styles.propertyDetailsContentText}>
            {` ${propertyDetails?.house_square_area} sqm` || "Fetching......"}
          </p>
          <p className={styles.propertyDetailsContentText}>
            ID# {` ${propertyDetails?.id}` || "Fetching......"}
          </p>
        </div>

        <div className={styles.propertyDetailsFullDescriptionText}>
          <p>
            {` ${propertyDetails?.house_property_details}` || "Fetching......"}
          </p>
        </div>
      </Box>

      <HorizontalBorder />

      <Box mt={4}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.viewExpensesButton}
            onClick={handleViewExpenses}
          >
            View Expenses
          </button>
          <button
            className={styles.deletePropertyButton}
            onClick={handleDeleteClick}
          >
            Delete Property
          </button>
        </div>

        {deleteError && (
          <div className={styles.errorMessage}>{deleteError}</div>
        )}
      </Box>

      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Photos
        </Typography>
        <Grid container spacing={2}>
          {propertyDetails?.house_pictures_urls.map((url, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={url}
                  alt={`Property image ${index + 1}`}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete property: {propertyDetails?.id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PropertyDetails;
